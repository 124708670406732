import { Component, OnInit, Input, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: '[high-school-sb-digit]',
  templateUrl: './high-school-sb-digit.component.html',
  styleUrls: ['./high-school-sb-digit.component.scss']
})
export class HighSchoolSbDigitComponent implements OnInit, OnChanges {

  @Input() value: any;

  digitValue: any;

  constructor(
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    console.log('this.value', this.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this._cd.detectChanges();
    console.log('changes', changes);

    this.digitValue = changes.value.currentValue;

  }

}
