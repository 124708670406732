import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighSchoolSbDigitComponent } from './high-school-sb-digit.component';



@NgModule({
  declarations: [HighSchoolSbDigitComponent],
  exports: [HighSchoolSbDigitComponent],
  imports: [
    CommonModule
  ]
})
export class HighSchoolSbDigitModule { }
