<svg viewBox="0 0 100 175" class="digit digit-{{digitValue}}">

  <defs>
    <radialGradient id="off">
      <stop offset="00%" stop-color="#222" />
      <stop offset="95%" stop-color="#111" />
    </radialGradient>
    <radialGradient id="on">
      <stop offset="20%" stop-color="#f9953e" />
      <stop offset="77%" stop-color="#ff1a00" />
    </radialGradient>
  </defs>

  <g>

    <circle cx="12.5" cy="12.5" r="10" />
    <circle cx="37.5" cy="12.5" r="10" />
    <circle cx="62.5" cy="12.5" r="10" />
    <circle cx="87.5" cy="12.5" r="10" />

    <circle cx="12.5" cy="37.5" r="10" />
    <circle cx="37.5" cy="37.5" r="10" />
    <circle cx="62.5" cy="37.5" r="10" />
    <circle cx="87.5" cy="37.5" r="10" />

    <circle cx="12.5" cy="62.5" r="10" />
    <circle cx="37.5" cy="62.5" r="10" />
    <circle cx="62.5" cy="62.5" r="10" />
    <circle cx="87.5" cy="62.5" r="10" />

    <circle cx="12.5" cy="87.5" r="10" />
    <circle cx="37.5" cy="87.5" r="10" />
    <circle cx="62.5" cy="87.5" r="10" />
    <circle cx="87.5" cy="87.5" r="10" />

    <circle cx="12.5" cy="112.5" r="10" />
    <circle cx="37.5" cy="112.5" r="10" />
    <circle cx="62.5" cy="112.5" r="10" />
    <circle cx="87.5" cy="112.5" r="10" />

    <circle cx="12.5" cy="137.5" r="10" />
    <circle cx="37.5" cy="137.5" r="10" />
    <circle cx="62.5" cy="137.5" r="10" />
    <circle cx="87.5" cy="137.5" r="10" />

    <circle cx="12.5" cy="162.5" r="10" />
    <circle cx="37.5" cy="162.5" r="10" />
    <circle cx="62.5" cy="162.5" r="10" />
    <circle cx="87.5" cy="162.5" r="10" />

  </g>

</svg>

<!-- <div class="digit digit-{{value}}">
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
</div> -->